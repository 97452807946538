import './Customer.scss';

import React from 'react';

import { useDialog2 } from '../Common/custom-hooks/useDialog2';
import { Header } from '../Layout/Header';
import CustomerList from './CustomerList/components/CustomerList';

const CustomerPage = () => {
    const { createDialog } = useDialog2();
    return (
        <>
            <Header title="Kunder" />
            <div className='box'>
                <CustomerList createDialog={createDialog} />
            </div>
        </>
    );
};

export default CustomerPage;
