import './ScheduleOverviewPage.scss';

import React from 'react';

import { CurrentStoreName } from '../Common/Common/CurrentStoreName';
import { Header } from '../Layout/Header';
import { StoreSchedule } from './StoreSchedule';

const ScheduleOverviewPage = () => (
    <>
        <Header
            title="Schema"
            subTitle={(
                <CurrentStoreName
                    prefix="| "
                    component="h2"
                    sx={{
                        fontSize: '18px',
                        fontWeight: 'bold',
                    }}
                />
            )}
        />
        <div className='box'>
            <div className='schedule-overview-page'>
                <StoreSchedule />
            </div>
        </div>
    </>
);

export default ScheduleOverviewPage;
