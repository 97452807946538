import { Typography, TypographyProps } from '@mui/material';
import React from 'react';

import { useStore } from '../context/store-context';
import { useSubStore } from '../context/sub-store-context';

type CurrentStoreNameProps = { prefix?: string, suffix?: string } & TypographyProps;

export const CurrentStoreName = ({ prefix, suffix, ...props }: CurrentStoreNameProps) => {
    const [,,,, store] = useStore();
    const { selectedSubStore } = useSubStore();

    const names = [store?.name, selectedSubStore?.name].filter(Boolean).join(' / ');

    return <Typography {...props}>{prefix}{names}{suffix}</Typography>;
}
