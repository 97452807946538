import './StoreAdministrationPage.scss';

import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { Box, Typography } from '@mui/material';
import React, { Children, cloneElement, PropsWithChildren, useState } from 'react';

import { useStore } from '../Common/context/store-context';
import { Header } from '../Layout/Header';
import SubStoreList from '../SubStore/components/SubStoreList';
import PrinterSettings from './PrinterSettings';
import { SmsSettingsTab } from './SmsSettingsTab';
import StoreMetadataForm from './StoreMetadata/StoreMetadataForm';

const Tabs = ({ children, onChange, tabValue }: { children: PropsWithChildren<any>, onChange: (target: number) => void; tabValue: number }) => {
    const isSelected = (index: number) => {
        return index === tabValue;
    }

    return (
        <Box display="flex" flexDirection="row" marginX={3} marginTop={2} style={{ gap: '15px' }} >
            {Children.map(children, (child, index) => {
                return cloneElement(child, {
                    key: index,
                    onClick: () => onChange(index),
                    isSelected: isSelected(index),
                });
            })}
        </Box>
    );
};

const Tab = ({ label, onClick, isSelected }: { label: string, onClick?: () => void; isSelected?: number }) => {
    return (
        <Box onClick={onClick} style={{
            background: isSelected ? '#FFF' : '#000',
            color: isSelected ? '#000' : '#FFF',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: 'bold',
            height: '36px',
            width: '200px',
            cursor: 'pointer',
            border: '1px solid #000',
        }}>
            {label}
        </Box>
    );
};

const StoreAdministrationPage = () => {
    const [,,,, store] = useStore();
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (newValue: number) => {
        setTabValue(newValue);
    };

    return (
        <>
            <Header title="Alla Listor" subTitle={(
                <>
                    <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }} component="h2">{`| ${store.name}`}</Typography>
                    <Tooltip title={`${store.name} id: ${store.id}`}><InfoIcon /></Tooltip>
                </>
            )} />
            <Box className='box'>
                <Tabs onChange={handleTabChange} tabValue={tabValue}>
                    <Tab label="Bokningslistor" />
                    <Tab label="Onlinebokning" />
                    <Tab label="Skrivarinställningar" />
                    <Tab label="SMS-Inställningar" />
                </Tabs>
                <div className='store-administration-page'>
                    {tabValue === 0 && <SubStoreList />}
                    {tabValue === 1 && <StoreMetadataForm />}
                    {tabValue === 2 && <PrinterSettings />}
                    {tabValue === 3 && <SmsSettingsTab />}
                </div>
            </Box>
        </>
    );
}

export default StoreAdministrationPage;
