import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { faArchive, faPlus, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Box, Card, CardContent, Grid, MenuItem, Typography } from '@material-ui/core';
import { Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import {
    SubStore,
    SubStoreQuery,
    SubStoreStatus,
    SystemUser,
    useAddMechanicMutation,
    useArchiveSubStoreMutation,
    useCreateSubStoreMutation,
    useRemoveMechanicMutation,
    useSubStoreQuery,
    useSystemUserQuery,
    useUpdateSubStoreMutation,
} from 'src/generated/graphql';
import { useConfirmDialog } from 'src/modules/Common/Common/ConfirmDialog';
import { useUser } from 'src/modules/Common/context/user-context';
import AppButton from 'src/modules/Common/Form/AppButton';
import AppSelect from 'src/modules/Common/Form/AppSelect';
import TextInput from 'src/modules/Common/Form/TextInput';
import AppTableButtonLarge from 'src/modules/Common/Tables/AppTableButtonLarge';
import { MechanicsTable } from 'src/modules/SubStore/components/MechanicsTable/MechanicsTable';

import { useStore } from '../../Common/context/store-context';
import AddMechanicDialog from './AddMechanicDialog';
import { SubStoreStatuses } from './data/SubStoreSelectOptions';
import { MaxInput } from './MaxInput';

type SubStoreFormProps = {
    id?: number;
};

type SubStoreFormValues = {
    id?: number;
    name: string;
    storeId: number;
    status: SubStoreStatus;
    users: SystemUser[];
    onlineBookingText: SubStore['onlineBookingText'];
};

function mapSubStoreForFormData(
    currentStoreId: number,
    subStore?: SubStoreQuery['subStore'],
) {
    return {
        id: subStore?.id ?? undefined,
        storeId: subStore?.store?.id ?? currentStoreId,
        name: subStore?.name ?? '',
        status: subStore?.status ?? SubStoreStatus.Active,
        onlineBookingText: subStore?.onlineBookingText,
    };
}

export function SubStoreForm({ id }: SubStoreFormProps) {
    const [storeId] = useStore();
    const [user] = useUser();

    const storeExists = id !== undefined;

    const history = useHistory();

    const { data: subStoreData, refetch } = useSubStoreQuery({
        variables: {
            id: id!,
        },
        onCompleted: (data) => {
            methods.reset(mapSubStoreForFormData(storeId, data.subStore));
        },
        skip: !storeExists,
    });

    const { data: userData } = useSystemUserQuery({
        variables: {
            id: user?.getUsername() ?? '',
        },
        skip: !user,
    });

    const methods = useForm<SubStoreFormValues>({
        defaultValues: mapSubStoreForFormData(storeId, subStoreData?.subStore),
        mode: 'onChange',
    });

    useEffect(() => {
        if (!subStoreData?.subStore?.users) return;
        if (subStoreData.subStore?.users) {
            setUsers(() => subStoreData.subStore.users as SystemUser[]);
        }
    }, [subStoreData?.subStore?.users]);

    const [users, setUsers] = React.useState<SystemUser[]>((subStoreData?.subStore?.users as SystemUser[]) ?? []);

    const [addMechanicDialogOpen, setAddMechanicDialogOpen] = React.useState(false);

    const [archiveSubStore] = useArchiveSubStoreMutation({
        onCompleted: () => {
            enqueueSnackbar('Lista arkiverad!', { variant: 'success' })
            history.push(`/store-administration`);
        },
        onError: () => enqueueSnackbar('Kunde inte arkivera lista!', { variant: 'error' }),
    });

    const [updateSubStore] = useUpdateSubStoreMutation({
        onCompleted: () => {
            enqueueSnackbar('Lista uppdaterad!', { variant: 'success' })
            history.push(`/store-administration`);
        },
        onError: () => enqueueSnackbar('Kunde inte uppdatera lista!', { variant: 'error' }),
    });

    const [createSubStore] = useCreateSubStoreMutation({
        onCompleted: () => {
            enqueueSnackbar('Lista skapad!', { variant: 'success' })
            history.push(`/store-administration`);
        },
        onError: () => enqueueSnackbar('Kunde inte skapa lista!', { variant: 'error' }),
    });

    const [removeMechanic] = useRemoveMechanicMutation({
        onCompleted: () => {
            enqueueSnackbar('Mekaniker borttagen!', { variant: 'success' })
            refetch({ id: id! });
        },
        onError: () => enqueueSnackbar('Kunde inte ta bort mekaniker!', { variant: 'error' }),
    });

    const [addMechanic] = useAddMechanicMutation({
        onCompleted: () => {
            enqueueSnackbar('Mekaniker tillagd!', { variant: 'success' })
            refetch({ id: id! });
        },
        onError: () => enqueueSnackbar('Kunde inte lägga till mekaniker!', { variant: 'error' }),
    });

    const confirmDialog = useConfirmDialog();

    const { enqueueSnackbar } = useSnackbar();

    const onMechanicsSelectClose = () => {
        setAddMechanicDialogOpen(false);
        refetch({ id: id! });
    };

    const onMechanicSelected = async (user?: SystemUser) => {
        if (!user) {
            return;
        }

        if (users.find((u) => u.id === user.id)) {
            enqueueSnackbar('Mekaniker finns redan i listan!', { variant: 'warning' });

            return;
        }

        if (id) {
            await addMechanic({
                variables: { subStoreId: id!, userId: user.id },
            });

            return;
        }

        setUsers((users) => [...users, user]);
    };

    const onRemoveMechanic = async (user: SystemUser) => {
        if (id) {
            await removeMechanic({
                variables: { subStoreId: id!, userId: user.id },
            });

            return;
        }
        setUsers((users) => users.filter((u) => u.id !== user.id));
    };

    const onSubmit = async (data: SubStoreFormValues) => {
        if (!methods.formState.isValid) {
            return;
        }

        if (id) {
            await updateSubStore({
                variables: {
                    input: {
                        id,
                        ...data,
                        userIds: users.map((u) => u.id),
                    },
                },
            });
        } else {
            await createSubStore({
                variables: {
                    input: {
                        ...data,
                        userIds: users.map((u) => u.id),
                    },
                },
            });
        }
    };

    const showRemoveUserDialog = (row: SystemUser) => {
        confirmDialog({
            content: (
                <>
                    Är du säker på att du vill ta bort <b>{row.name}</b> från listan?
                </>
            ),
            buttons: [
                { label: 'Avbryt', variant: 'outlined' },
                { label: 'Radera', action: () => onRemoveMechanic(row) },
            ],
        });
    };

    const onArchiveList = async () => {
        if (!id) {
            return;
        }

        await archiveSubStore({
            variables: {
                id,
            },
        });
    };

    const showArchiveListDialog = () => {
        confirmDialog({
            content: (
                <>
                    Är du säker på att du vill arkivera listan?
                </>
            ),
            buttons: [
                { label: 'Avbryt', variant: 'outlined' },
                { label: 'Arkivera', action: () => onArchiveList() },
            ],
        });
    };

    return (
        <FormProvider {...methods}>
            <form
                onSubmit={methods.handleSubmit(onSubmit)}
                style={{ width: '100%' }}
            >
                <Stack gap={2}>
                    <Card>
                        <CardContent>
                            <Stack gap={2} direction="row">
                                <Stack
                                    gap={2}
                                    direction="column"
                                    flexGrow={1}
                                    sx={{
                                        marginTop: '22px',
                                        flexBasis: '33%',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <TextInput
                                        name="name"
                                        label="Namn"
                                        validation={{
                                            required: 'Namn är obligatoriskt',
                                        }}
                                    />
                                    <AppSelect
                                        rules={{
                                            required: 'Butik är obligatoriskt',
                                            validate: (value) => {
                                                if (value === 0) {
                                                    return 'Butik är obligatoriskt';
                                                }
                                                return true;
                                            },
                                        }}
                                        disabled={true}
                                        name="storeId"
                                        label="Butik"
                                        defaultValue={storeId}
                                    >
                                        {userData?.systemUser?.stores?.map((store) => (
                                            <MenuItem key={store.id} value={store.id}>
                                                {store.name}
                                            </MenuItem>
                                        ))}
                                    </AppSelect>
                                    <AppSelect
                                        name="status"
                                        label="Status"
                                        defaultValue={methods.getValues('status') ?? SubStoreStatus.Active}
                                    >
                                        {SubStoreStatuses.map((user) => (
                                            <MenuItem key={user.id} value={user.id}>
                                                {user.name}
                                            </MenuItem>
                                        ))}
                                    </AppSelect>
                                </Stack>
                                <Stack
                                    gap={2}
                                    direction="row"
                                    flexGrow={2}
                                    justifyContent="stretch"
                                    sx={{
                                        flexBasis: '66%',
                                        '& > *': { flexGrow: 1 }
                                    }}
                                >
                                    <MaxInput
                                        name="onlineBookingText"
                                        label="Informationstext Onlinebokning"
                                        maxChars={500}
                                        methods={methods}
                                        textInputProps={{
                                            rows: 10
                                        }}
                                    />
                                </Stack>
                            </Stack>
                        </CardContent>
                    </Card>
                    <Stack direction="row" gap={2}>
                        <AppButton type="submit" icon={faCheckCircle} disabled={!methods.formState.isValid}>
                            {id ? 'Spara' : 'Skapa'}
                        </AppButton>
                        {id && subStoreData?.subStore.status == SubStoreStatus.Active && (
                            <AppButton
                                type="button"
                                icon={faArchive}
                                color={'default'}
                                onClick={showArchiveListDialog}
                            >
                                Arkivera lista
                            </AppButton>
                        )}
                    </Stack>
                    <Card>
                        <CardContent>
                            <Grid container>
                                <Grid item xs={12}>
                                    <AddMechanicDialog
                                        skipMechanicIds={users.map((u) => u.id)}
                                        open={addMechanicDialogOpen}
                                        subStoreId={methods.getValues('storeId')!}
                                        onAdd={onMechanicSelected}
                                        onClose={onMechanicsSelectClose}
                                    />
                                    <Box paddingBottom={2}>
                                        <Typography variant='body1' color='textSecondary'>
                                            Mekaniker
                                        </Typography>
                                    </Box>
                                    <Box paddingRight={4} display={'inline'}>
                                        <AppButton
                                            icon={faPlus}
                                            onClick={(event) => {
                                                event.preventDefault();

                                                setAddMechanicDialogOpen(true);
                                            }}
                                            type="button"
                                        >
                                            Lägg till mekaniker
                                        </AppButton>
                                    </Box>
                                    {(!id && users.length > 0) && (
                                        <Box display={'inline'}>
                                            <AppButton icon={faTrash} onClick={() => setUsers([])}>
                                                Töm lista
                                            </AppButton>
                                        </Box>
                                    )}
                                    <MechanicsTable
                                        actionName="Ta bort"
                                        mechanics={users}
                                        rowClickedAction={(row) => (
                                            <Grid container>
                                                <Grid item>
                                                    <AppTableButtonLarge
                                                        icon={faTimes}
                                                        onClick={() => showRemoveUserDialog(row)}
                                                        label=''
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Stack>
            </form>
        </FormProvider>
    );
}
