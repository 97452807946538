import './ScheduleAdministrationPage.scss';

import Card from '@material-ui/core/Card/Card';
import CardContent from '@material-ui/core/CardContent';
import { Stack, Typography } from '@mui/material';
import React from 'react';

import { CurrentStoreName } from '../Common/Common/CurrentStoreName';
import { ScheduleDeviationsTableWrapper } from './ScheduleDeviation/ScheduleDeviationTableWrapper';
import ScheduleStandardForm from './ScheduleStandard/ScheduleStandardForm';
import { TooEasySync } from './TooEasySync/TooEasySync';

const ScheduleAdministrationPage = () => (
    <>
        <Stack
            className="header"
            direction="row"
            sx={{
                flexDirection: 'row',
                alignItems: 'flex-end',
                marginBottom: '5px',
                gap: 0.8,
            }}
        >
            <Typography
                component="h1"
                sx={{
                    fontSize: '18px',
                    fontWeight: 'bold',
                }}
            >Schema</Typography>
            <CurrentStoreName
                prefix="| "
                component="h2"
                sx={{
                    fontSize: '18px',
                    fontWeight: 'bold',
                }}
            />
        </Stack>
        <div className="box">
            <div className="schedule-page">
                <div className="schedules-standard-form">
                    <ScheduleStandardForm />
                </div>
                <div className="schedules-deviation-table">
                    <Card>
                        <CardContent>
                            <Typography color="textSecondary">
                                Avvikande arbetstider
                            </Typography>
                            <ScheduleDeviationsTableWrapper />
                        </CardContent>
                    </Card>
                    <Card style={{ marginTop: 40 }}>
                        <CardContent>
                            <Typography color="textSecondary">
                                Info
                            </Typography>
                            <p>
                                Ange hur många minuter en service tar i genomsnitt och
                                hur många minuter som finns tillgängliga per dag.
                            </p>
                            <p>
                                <em>
                                    Ex. En service tar 30 minuter. Två mekaniker 8 timmar per dag innebär 960
                                    minuter per dag.
                                    <br />
                                    Verkstaden hanterar i genomsnitt 32 cyklar på en dag.
                                </em>
                            </p>
                        </CardContent>
                    </Card>
                    <Card style={{ marginTop: 40 }}>
                        <CardContent>
                            <Typography color="textSecondary">
                                Synkronisera TooEasy
                            </Typography>
                            <p>
                                Synkronisera avvikande tider med TooEasy.
                                <br />
                                Om en butik har angivit ett TooEasy ID så kommer alla butikens avvikande tider
                                skrivas över med det som är angivet i TooEasy.
                            </p>
                            <p>
                                <TooEasySync />
                            </p>
                        </CardContent>
                    </Card>
                </div>
            </div>
        </div>
    </>
);

export default ScheduleAdministrationPage;
