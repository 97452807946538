import React from 'react';

import { Header } from '../Layout/Header';
import { HeadsLogTable } from './HeadsLog/HeadsLogTable';

export default function HeadsIntegrationPage() {
    return (
        <div>
            <Header title="Heads-integration" />
            <div className="box">
                <HeadsLogTable />
            </div>
        </div>
    );
}
