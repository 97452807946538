import { TextFieldProps } from '@material-ui/core/TextField/TextField';
import { Box, BoxProps, Stack } from '@mui/material';
import React from 'react';
import { FieldName, FieldValues, UseFormMethods } from 'react-hook-form';

import TextInput from '../../Common/Form/TextInput';

type MaxInputProps<T extends FieldValues,> = {
    name: FieldName<T>; // Would be nice with type from form state
    label: string;
    maxChars: number;
    methods: UseFormMethods<T>;
    textInputProps?: TextFieldProps;
} & BoxProps;

const useMaxInput = <T,>(
    methods: MaxInputProps<T>['methods'],
    name: MaxInputProps<T>['name'],
) => {
    const {
        watch,
        setError,
        formState: { errors },
    } = methods;

    const clearErrors = () => methods.clearErrors(name);

    const toManyCharsError = () => setError(name, {
        type: 'manual',
        message: 'Texten är för lång',
    });

    const currentText = String(watch(name));

    return {
        clearErrors,
        toManyCharsError,
        length: (currentText || '').length,
        errors
    };
}

export const MaxInput = <T,>({
    name,
    label,
    methods,
    maxChars = 320,
    textInputProps,
    ...boxProps
}: MaxInputProps<T>) => {
    const {
        clearErrors,
        toManyCharsError,
        length,
    } = useMaxInput(methods, name);

    return (
        <Box {...boxProps}>
            <Stack
                justifyContent="space-between"
                direction="row"
                sx={{
                    paddingBottom: '5px',
                }}
            >
                <Box flexGrow={1} />
                <Box
                    flexShrink={1}
                    sx={{ fontSize: '12px' }}
                >{length}/{maxChars}</Box>
            </Stack>
            <TextInput
                name={name}
                label={label}
                multiline
                rows={4}
                onChange={(event) => {
                    if (event.target.value.length > maxChars) {
                        toManyCharsError();

                        return event;
                    }

                    clearErrors();

                    return event;
                }}
                {...textInputProps}
            />
        </Box>
    );
}
