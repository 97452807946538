import React from 'react';

import { useStore } from '../../Common/context/store-context';
import { useSubStore } from '../../Common/context/sub-store-context';
import ScheduleDeviationTable from './ScheduleDeviationTable';

export function ScheduleDeviationsTableWrapper() {
    const [storeId] = useStore();
    const { selectedSubStoreId: subStoreId } = useSubStore();

    if (!storeId) {
        return null;
    }

    return <ScheduleDeviationTable key={`${storeId}-${subStoreId}`} storeId={storeId} subStoreId={subStoreId} />
}
