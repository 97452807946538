import Box from '@material-ui/core/Box';
import React from 'react';
import { SmsStatisticsTable } from 'src/modules/SmsOvererview/SmsStatisticsTable/SmsStatisticsTable';

import { Header } from '../Layout/Header';

export const SmsOverviewPage = () => (
    <div>
        <Header title='Skickade SMS' />
        <div className='box'>
            <Box p={2} pt={0}>
                <SmsStatisticsTable />
            </Box>
        </div>
    </div>
);
