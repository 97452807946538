import './UserAdministration.scss';

import { faFont, faLockOpen, faPlus, faStore, faTrash } from '@fortawesome/free-solid-svg-icons';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import { useSnackbar } from 'notistack';
import React, { useRef } from 'react';
import { SystemUser } from 'src/generated/graphql';
import { useConfirmDialog } from 'src/modules/Common/Common/ConfirmDialog';
import { useUser } from 'src/modules/Common/context/user-context';
import { useDialog3 } from 'src/modules/Common/custom-hooks/useDialog3';
import AppButton from 'src/modules/Common/Form/AppButton';
import AppTableButtonLarge from 'src/modules/Common/Tables/AppTableButtonLarge';
import { CreateUserDialog } from 'src/modules/UserAdministration/components/CreateUserDialog/CreateUserDialog';
import { EditPasswordDialog } from 'src/modules/UserAdministration/components/EditPasswordDialog/EditPasswordDialog';
import { EditUserDialog } from 'src/modules/UserAdministration/components/EditUserDialog/EditUserDialog';
import { EditUserStoresDialog } from 'src/modules/UserAdministration/components/EditUserStoresDialog/EditUserStoresDialog';
import { UserTable } from 'src/modules/UserAdministration/components/UserTable/UserTable';
import {
    useAddSystemUserToGroupMutation,
    useDeleteUserMutation,
    useRemoveSystemUserFromGroupMutation,
} from 'src/modules/UserAdministration/hooks/useUserMutations';

import { Header } from '../Layout/Header';

export const UserAdministrationPage = () => {
    const [user] = useUser();
    const table = useRef<any>({});

    const { createDialog } = useDialog3();
    const confirmDialog = useConfirmDialog();
    const [callDeleteUser, { loading: loading1 }] = useDeleteUserMutation();
    const [callAddSystemUserToGroup, { loading: loading2 }] = useAddSystemUserToGroupMutation();
    const [callRemoveSystemUserFromGroup, { loading: loading3 }] = useRemoveSystemUserFromGroupMutation();
    const { enqueueSnackbar } = useSnackbar();

    const loading = loading1 || loading2 || loading3;

    const openCreateUserDialog = () => {
        const ref = createDialog((ref: any) => (
            <CreateUserDialog
                dialogRef={ref}
            />
        ));
        ref.onClose.push(table.current.refetch);
    };

    const openEditStoresDialog = (userId: string, assignedStores: any[]) => {
        const ref = createDialog((ref: any) => (
            <EditUserStoresDialog
                dialogRef={ref}
                userId={userId}
                stores={assignedStores}
            />
        ));
        ref.onClose.push(table.current.refetch);
    };

    const openEditUserDialog = (row: SystemUser) => {
        const ref = createDialog((ref: any) => (
            <EditUserDialog
                dialogRef={ref}
                user={row}
            />
        ));
        ref.onClose.push(table.current.refetch);
    };

    const openEditPasswordDialog = (row: SystemUser) => {
        const ref = createDialog((ref: any) => (
            <EditPasswordDialog
                dialogRef={ref}
                user={row}
            />
        ));
        ref.onClose.push(table.current.refetch);
    };

    const deleteUser = async (row: SystemUser) => {
        await callDeleteUser({
            variables: {
                userId: row.id,
            },
        });

        enqueueSnackbar('Användaren borttagen', { variant: 'warning' });
        table.current.refetch();
    };

    const showDeleteUserDialog = (row: SystemUser) => {
        confirmDialog({
            content: <>Är du säker på att du vill radera <b>{row.name}</b>?<br />Det här går inte att ångra.</>,
            buttons: [
                { label: 'Avbryt', variant: 'outlined' },
                { label: 'Radera', action: () => deleteUser(row) },
            ],
        });
    };

    const updateUserRole = async (user: SystemUser, group: string, value: boolean) => {
        if (value) {
            await callAddSystemUserToGroup({
                variables: {
                    userId: user.id,
                    groupName: group,
                },
            });
        } else {
            await callRemoveSystemUserFromGroup({
                variables: {
                    userId: user.id,
                    groupName: group,
                },
            });
        }

        table.current.refetch();
    };

    return (
        <div>
            <Header title="Användare" />
            <div className="box">
                <Box p={2} pt={0}>
                    <div className="button-wrapper">
                        <AppButton
                            icon={faPlus}
                            onClick={openCreateUserDialog}
                        >
                            Ny användare
                        </AppButton>
                    </div>
                </Box>
                <UserTable
                    loading={loading}
                    innerRef={table}
                    actions={(row: SystemUser) => {
                        const isMe = user.username === row.id;
                        return (
                            <div style={{ textAlign: 'center', display: 'inline-block' }}>
                                <Grid container>
                                    <Grid item>
                                        <FormControlLabel
                                            disabled={isMe}
                                            checked={row.groups.includes('StoreAdmins')}
                                            onChange={(e: any) => updateUserRole(row, 'StoreAdmins', e.target.checked)}
                                            control={<Checkbox color="primary" />}
                                            label="Butiksägare"
                                            labelPlacement="bottom"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            disabled={isMe}
                                            checked={row.groups.includes('Admins')}
                                            onChange={(e: any) => updateUserRole(row, 'Admins', e.target.checked)}
                                            control={<Checkbox color="primary" />}
                                            label="Admin"
                                            labelPlacement="bottom"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <AppTableButtonLarge
                                            icon={faStore}
                                            onClick={() => openEditStoresDialog(row.id, row.assignedStores)}
                                            label="Butiker"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <AppTableButtonLarge
                                            icon={faFont}
                                            onClick={() => openEditUserDialog(row)}
                                            label="Namn"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <AppTableButtonLarge
                                            icon={faLockOpen}
                                            onClick={() => openEditPasswordDialog(row)}
                                            label="Lösenord"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <AppTableButtonLarge
                                            icon={faTrash}
                                            disabled={isMe}
                                            onClick={() => showDeleteUserDialog(row)}
                                            label="Ta bort"
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        );
                    }}
                />
            </div>
        </div>
    );
};

export default UserAdministrationPage;
